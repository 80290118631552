.mantine-Stepper-steps {
}

.mantine-Stepper-content {
    height: 100%;
}

.stepper-width {
    @apply sm:max-w-[75%] md:max-w-[50rem];
}

.mantine-Stepper-steps {
    @apply stepper-width sm:m-auto sm:min-w-[40rem] !important;
}