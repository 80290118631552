.link--dark:hover {
  background-color: var(--mantine-color-dark-8);
}
.link--light:hover {
  background-color: var(--mantine-color-gray-3);
}

.link--dark[data-active] {
  background-color: var(--mantine-color-dark-6);
}
.link--light[data-active] {
  background-color: var(--mantine-color-gray-3);
}

.mantine-Drawer-content {
  height: 100% !important;
}

.mantine-Drawer-header {
  height: 5rem !important;
}

.mantine-Drawer-body {
  height: calc(100vh - 5rem) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.mantine-Popover-dropdown {
  padding: 0 !important;
}
