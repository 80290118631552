.photo-album-media-container {
  transition: all 0.2s ease-in-out;
}
.photo-album-media-container:hover {
  scale: 1.05;
}

.photo-album-media-container:hover > .photo-album-media-container__video-indicator {
  opacity: 80;
}

.folder-options,
.photo-options {
  visibility: hidden;
}

.folder-options--mobile,
.photo-options--mobile {
  visibility: visible;
}

.folder:hover .folder-options {
  visibility: visible;
}

.photo {
  box-sizing: border-box !important;
}

.photo:hover .photo-options {
  visibility: visible;
}

.photo img {
  width: 100% !important;
  height: 100% !important;
}


.yarl__slide {
  padding: 0 !important;
}
