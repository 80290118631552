@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100%;
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  overflow: auto;
}

:global(.mantine-ScrollArea-viewport > div) {
  display: block !important;
}

.mantine-ScrollArea-viewport > div {
  display: block !important;
}

.mantine-Menu-dropdown {
  border: none !important;
  border-radius: 0.5rem !important;
}

.mantine-Modal-header {
  @apply dark:bg-dark-200 bg-grey-200 !important;
}
.mantine-Modal-body {
  @apply p-4 overflow-auto h-full flex flex-col !important;
}
.mantine-Modal-content {
  @apply overflow-hidden flex flex-col !important;
}
/*
.mantine-Modal-body {
  @apply h-full overflow-auto flex p-0 !important;
} */

.mantine-ScrollArea-viewport {
  @apply pb-0 !important;
}

.card {
  @apply dark:bg-dark-100 bg-grey-200 bg-opacity-50 p-4 rounded-lg;
}

.bottom-drawer-content,
.bottom-drawer-overlay {
  z-index: 10 !important;
}
