.gradient-bg {
  background: rgb(20, 20, 20);
  background: linear-gradient(
    45deg,
    rgba(20, 20, 20, 1) 4%,
    rgba(41, 41, 41, 1) 49%,
    rgba(48, 48, 48, 1) 92%
  );
}

.page {
  position: absolute;
  left: 0px;
  right: 0px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
